@import "../../global.scss";

.navLinks {
  display: flex;
  justify-content: space-between;
  width: 30%;
  height: 70px;
  font-size: 30px;
  margin-right: 30px;

  a {
    text-decoration: none;
    color: steelblue;
  }
}
