@import '../../global.scss';
  
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 100px;
  }

