@import './global.scss';

body {
  font-family: 'Josefin Slab', serif;
  margin: 0;
}
.background {
  height: 100vh;
  width: 100vw;
}
.App {
  height: 100vh;
  width: 100vw;


  .bgImgWrapper {
    overflow: hidden;
    position: relative;

    .bgImg {
      opacity: .6;
      position: absolute;
      left: 0;
      top: 0;
      width: 3000px;
      height: 3000px;
      z-index: -1;
    }

    .bgContent {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}