@import '../../global.scss';

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100vw;
  height: 20vh;
  background-color: white;
  top: 0;
  z-index: 3;
  border-bottom: $maincolor 5px solid;
}