$maincolor: #2A3B73;
$bgc: #b1bdc9;


@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@100;300&display=swap');



// * {
//   border: 1px solid gold;
// }