@import '../../global.scss';

.images {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 90vw;
  
  .mainImg {
    display: flex;
    justify-content: center;

    .img1 {
      width:60%;
      height: 65%;
      border-radius: 15px;
      margin: 10px;
      // box-shadow: -10px -10px 12px $bgc inset;
    }
  }

  .otherImgs {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 388.78px;

    .img2 {
      width: 30%;
      // height: 388.78px;
      border-radius: 15px;
      margin: 20px;
      margin-right: 50px;
      
    }
    
    .img3 {
      width: 30%;
      // height: 388.78px;
      border-radius: 15px;
      margin: 20px;
      margin-left: 50px;
    }
  }
}