@import "../../global.scss";

form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.formSubmitted {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
