@import "../../global.scss";

.logoContainer {
  // padding-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    // border: gold 2px solid;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    padding-left: 15px;
  }
}
